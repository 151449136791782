import React, { useState } from 'react'
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import "./Menu.css"
import starterImage from './starter.png';
import sushiSetImage from './sushi_set.png';
import sushiRolls from './sushi_rolls.png'
import snacksImage from './sushi_snacks.png';
import cookedRollsImage from './salmon.png';
import soupImage from './soup.png';
import kcImage from './korean_chicken.png';
import noodleImage from './noodle.png';
import nigiriImage from './sushi_nigiri.png';
import drinksImage from './drinks.png';
import { useNavigate } from 'react-router-dom';

const Menu = () => {

    const navigate = useNavigate();

    const menuItems = [
        "BAŞLANGIÇLAR",
        "SUSHİ SETLER",
        "SUSHİ ROLLAR",
        "PİŞMİŞ ROLLAR",
        "ÇORBALAR",
        "KOREAN CHİCKEN",
        "NOODLE",
        "SUSHİ NİGİRİ",
        "ATIŞTIRMALIKLAR",
        "İÇECEKLER"
      ];
    
      const getImage = (item) =>{
        if(item === "BAŞLANGIÇLAR"){
            return starterImage;
        }
        else if(item === "SUSHİ SETLER"){
            return sushiSetImage;
        }
        else if(item === "SUSHİ ROLLAR"){
            return sushiRolls;
        }
        else if(item === "PİŞMİŞ ROLLAR"){
            return cookedRollsImage;
        }
        else if(item === "ÇORBALAR"){
            return soupImage;
        }
        else if(item === "KOREAN CHİCKEN"){
            return kcImage;
        }
        else if(item === "NOODLE"){
            return noodleImage;
        }
        else if(item === "SUSHİ NİGİRİ"){
            return nigiriImage;
        }
        else if(item === "ATIŞTIRMALIKLAR"){
           return snacksImage;
        }
        else if(item === "İÇECEKLER"){
            return drinksImage;
        }
      }

    return (
        <div>
            <div className='sticky'>
            <NavBar/>
                <div className="scrollmenu ">
                {menuItems.map((item,index) => (
                    <a key={index}
                     onClick={()=> {                         
                        
                        navigate(`/menu/${item}`,{state:{ type:item}})
                    }}
                    >{item}</a>
                ))
                }
                </div>
            </div>
            <div className='container-fluid menu-row '>
                <div className='row m-auto'>
                    <div id="gallery">
                        <div id="header">
                            <div id="logo">MENÜ</div>
                        </div>
                        <section className="service-categories text-xs-center">
                            <div className="container-fluid">
                                <div className="row">                   
                                {menuItems.map((item,index) => (
                                    <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-6" onClick={()=> {                         
                                        navigate(`/menu/${item}`,{state:{  type:item}})
                                    }}>
                                        <div className="card service-card card-inverse">
                                            <div className="card-block">
                                            <img
                                                className="image"
                                                src={getImage(item)}
                                                alt={item}
                                            />
                                            <h1 className="title"> <b>{item}</b></h1>
                                            </div>
                                        </div>
                                    </div>   
                                ))}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
export default Menu;