import React, { useState } from 'react'
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import "./Menu.css"
import Foodcard from '../../components/foodcard';
import { useEffect } from 'react';
import { fetchProducts } from '../../statemanagement/actions/products';
import {useSelector,useDispatch} from 'react-redux';
import LoadingModal from '../../components/LoadingModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
require.context('../../assets', false, /\.(png|jpe?g|svg)$/);

const MenuType = () => {

    const dispatch = useDispatch();
    const {products,loading} = useSelector(state => state.ProductsReducer);
    const navigate = useNavigate();
    const {state} = useLocation();
    const [currentProducts,setCurrentProducts] = useState([])
    const [selectedItem,setSelectedItem] = useState({});
    const [show,setShow] = useState(false);
    const [images,setImages] = useState({});    
    
    const menuItems = [
        "BAŞLANGIÇLAR",
        "SUSHİ SETLER",
        "SUSHİ ROLLAR",
        "PİŞMİŞ ROLLAR",
        "ÇORBALAR",
        "KOREAN CHİCKEN",
        "NOODLE",
        "SUSHİ NİGİRİ",
        "ATIŞTIRMALIKLAR",
        "İÇECEKLER"
    ];

    function normalizeTurkishCharacters(text) {
        return text
            .replace(/ı/g, "i")
            .replace(/İ/g, "I")
            .replace(/ş/g, "s")
            .replace(/Ş/g, "S")
            .replace(/ç/g, "c")
            .replace(/Ç/g, "C")
            .replace(/ğ/g, "g")
            .replace(/Ğ/g, "G")
            .replace(/ü/g, "u")
            .replace(/Ü/g, "U")
            .replace(/ö/g, "o")
            .replace(/Ö/g, "O")
            .toUpperCase(); // Make sure it's in uppercase for consistent comparisons
    }

    useEffect(() => {
    if(selectedItem.type === "Başlangıçlar"){
        const starters = importAll(require.context("../../assets/Starters", false, /\.(png|jpe?g|svg)$/));
        setImages(starters);
    }
    else if(selectedItem.type === "Sushi Sets"){
        const sushisets = importAll(require.context("../../assets/Sushi Sets", false, /\.(png|jpe?g|svg)$/));
        setImages(sushisets);
    }
    else if(selectedItem.type === "Sushi Rolls"){
        const sushirolls = importAll(require.context("../../assets/Sushi Rolls", false, /\.(png|jpe?g|svg)$/));
        setImages(sushirolls)
    }
    else if(selectedItem.type === "Nigiri"){
        const Nigiri = importAll(require.context("../../assets/Nigiri", false, /\.(png|jpe?g|svg)$/));
        setImages(Nigiri);

    }
    else if(selectedItem.type === "Snacks"){
        const Snacks = importAll(require.context("../../assets/Snacks", false, /\.(png|jpe?g|svg)$/));
        setImages(Snacks);

    }
    else if(selectedItem.type === "Korean Fried Chicken"){
        const KFC = importAll(require.context("../../assets/Korean Fried Chicken", false, /\.(png|jpe?g|svg)$/));
        setImages(KFC);
    }
    else if(selectedItem.type === "Pişmiş Rollar"){
        const YAKI = importAll(require.context("../../assets/Yaki Sushi", false, /\.(png|jpe?g|svg)$/));
        setImages(YAKI);
    }
    else if(selectedItem.type === "Noodles"){
        const Noodles = importAll(require.context("../../assets/Noodles", false, /\.(png|jpe?g|svg)$/));
        setImages(Noodles);
    }
    else if(selectedItem.type === "Drinks"){
        const Drinks = importAll(require.context("../../assets/Drinks", false, /\.(png|jpe?g|svg)$/));
        setImages(Drinks);
    }
    else if(selectedItem.type === "Çorba/Soup"){
        const Drinks = importAll(require.context("../../assets/Soup", false, /\.(png|jpe?g|svg)$/));
        setImages(Drinks);
    }
    },[selectedItem]);
    
    
    function importAll(r) {
        let images = {};
            r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
        return images
    }

    function filterProductsByType(products) {
        const groupedProducts = {};
    
        products.forEach(product => {
            const productType = product.type;
            if (!groupedProducts[productType]) {
                groupedProducts[productType] = [];
            }
            groupedProducts[productType].push(product);
        });
        
        const normalizedType = normalizeTurkishCharacters(state.type);        
        let coock = [];
        if (normalizedType === "SUSHI SETLER") {
            coock = groupedProducts["Sushi Sets"] || [];
        } else if (normalizedType === "SUSHI ROLLAR") {
            coock = groupedProducts["Sushi Rolls"] || [];
        } else if (normalizedType === "SUSHI NIGIRI") {
            coock = groupedProducts["Nigiri"] || [];
        } else if (normalizedType === "ATISTIRMALIKLAR") { // Note: Normalized form
            coock = groupedProducts["Snacks"] || [];
        } else if (normalizedType === "KOREAN CHICKEN") {
            coock = groupedProducts["Korean Fried Chicken"] || [];
        } else if (normalizedType === "PISMIS ROLLAR") { // Note: Normalized form
            coock = groupedProducts["Pişmiş Rollar"] || [];
        } else if (normalizedType === "NOODLE") {
            coock = groupedProducts["Noodles"] || [];
        } else if (normalizedType === "ICECEKLER") { // Note: Normalized form
            coock = groupedProducts["Drinks"] || [];
        } else if (normalizedType === "CORBALAR") { // Note: Normalized form
            coock = groupedProducts["Çorba/Soup"] || [];
        }
        else if (normalizedType === "BASLANGICLAR") { // Note: Normalized form
            coock = groupedProducts["Başlangıçlar"] || [];
        }
        return coock;
    }

    useEffect(() => {
        if (state === null) {
            navigate('/menu'); // Redirect to the menu page if state is null
            return;
        }
    
        dispatch(fetchProducts()); // Fetch products when the component mounts
    }, [state, navigate, dispatch]);
    
    useEffect(() => {
        if (!loading && products.length > 0 && state !== null) {
            const filteredProducts = filterProductsByType(products); // Filter products based on state.type            
            setCurrentProducts(filteredProducts || []); // Ensure it's never undefined, set to an empty array if no match
        }
    }, [loading, products, state]); // Ensure this runs whenever loading, products, or state changes

    return (
        <div>
            { !loading && currentProducts?.length > 0 && 
            <>
            <div className='sticky'>
            <NavBar/>
                <div className="scrollmenu ">
                {menuItems.map((item,index) => (
                    <a
                     onClick={()=> {                         
                        navigate(`/menu/${item}`,{state:{ type:item}})
                    }}
                    >{item}</a>
                ))
                }
                </div>
            </div>

            <div className='container-fluid menu-row '> 
                <div className='row m-auto'>
                    <div id='menu' className='menu-col mt-3' >
                        <h1 className='pt-3 heading'
                        >
                            {state.type}
                        </h1>
                        <div className='row'>
                            {currentProducts.map((item) => (
                                <Foodcard onClick={()=>{setShow(true); setSelectedItem(item)}} item={item}></Foodcard>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer/>
            </>
        }
            <LoadingModal visible={loading || currentProducts.length == 0} />
            <Modal
            size='lg'
            show={show}
            onHide={()=>setShow(false)}
            backdrop="static"
            >
            
            <Modal.Body >
        
            <div className='row pt-3'>
                <div className='col-12'>
                <h4 className='p-2 modal-food-title '> {selectedItem.name}</h4>

                </div>
                <div className='col-12'>
                    <img  className="w-100 modal-food-image px-2 py-1 d-block" src={images[`${selectedItem.src}.jpg`]} alt=""/>
                </div>
                <div className='col-12'>
                <h4 className='p-2 modal-food-description '> {selectedItem.description}</h4>
                </div>
                
                
            </div>
            <div className='row mx-3 mt-3'>
                
                <button onClick={()=>setShow(false)} className="btn me-2b btn-ekle">Kapat </button>
            </div>

            </Modal.Body>


            </Modal>
        </div>
    )
}
export default MenuType;